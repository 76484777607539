import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueI18n from 'vue-i18n';
import en from 'vuetify/src/locale/en';
import uk from 'vuetify/src/locale/uk';
import ru from 'vuetify/src/locale/ru';

Vue.use(Vuetify);


export default new Vuetify({
    lang: {
        locales: { en, uk, ru },
        current: 'en',
    }
});
