import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Cabinet',
    path: '/cabinet',
    component: () => import('../views/Cabinet.vue'),
    children:[
      {
        path: 'calendar',
        name: 'Calendar',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Calendar.vue')
      },
      {
        path: 'home',
        name: 'Home',
        component: Home
      }
    ]
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/Registration.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Cabinet.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
