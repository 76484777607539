<template>
  <v-container>
    <v-row class="text-center">
     {{ $t('message') }}
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      name: 'HelloWorld'
    })
  }
</script>
